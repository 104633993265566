import React, { useState, useEffect } from "react";

function Header () {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.mvdesign.rs/');
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);  // Set loading to false after data is fetched
            }
        };

        fetchData();

    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }
    console.log(data);

    return (
        <div className="top-0 py-1 lg:py-2 w-full bg-transparent lg:relative z-50 dark:bg-gray-900">
            <div className="w-full sm:px-4  py-2 rounded-sm flex md:flex-no-wrap xs:flex-wrap md:gap-4 xs:gap-1 justify-center dark:bg-gray-200 bg-gray-800 text-white dark:text-black cursor-pointer md:text-lg md:font-semibold xs:text-sm">
                <div className="px-4 border-b-2 dark:border-blue-500 border-yellow-600 hover:border-b-2 hover:border-yellow-600 rounded-b-md">
                    Posts
                </div>
                <div className="px-4 hover:border-b-2 dark:hover:border-blue-500 hover:border-yellow-600 rounded-b-md">
                    About
                </div>
                <div className="px-4 hover:border-b-2 dark:hover:border-blue-500 hover:border-yellow-600 rounded-b-md">
                    Gallery
                </div>
                <div className="px-4 hover:border-b-2 dark:hover:border-blue-500 hover:border-yellow-600 rounded-b-md">
                    More
                </div>
            </div>
        </div>
    );
}

export default Header;